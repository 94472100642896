import React from "react";
import { Link } from "gatsby";

import "./pillsHeader.css";

const PillsHeader = ({ data, lang }) => {
  return (
    <ul className="moneymenuservices">
      {data.map(({ type, text, url, img }, index) => {
        const icon = (
          <img
            loading="lazy" // Consider using lazy loading for better performance
            src={img.url}
            alt={type === "send" ? "fast" : "mobile"}
            width="24" // Adjust width and height according to your design
            height="24"
          />
        );

        const placeholder = (
          <span
            style={{
              backgroundColor: "transparent",
              width: "24px", // Adjust width and height according to your design
              height: "24px",
              display: "inline-block", // Ensure the placeholder takes up space
            }}
          />
        );

        return (
          <Link to={url} key={index}>
            <li className="pillHeader-container">
              {icon || placeholder}
              <span className="pillsHeader">{text}</span>
            </li>
          </Link>
        );
      })}
    </ul>
  );
};

export { PillsHeader };
